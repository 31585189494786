/**
 * 検索ページ（パス: `/search/` ）
 */
import * as React from "react"
import { useState, useCallback, useMemo } from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import debounce from "lodash/debounce"
import isNil from "lodash/isNil"

import { FiSearch } from "react-icons/fi"

import HeaderImage from "../components/tbpage/header-image"
import Layout from "../components/layout.js"
import PageTitle from "../components/page-title"

const Page = ({ data: {
  allTbSearchItem: { nodes: allItems },
  header_image_pc,
  header_images_sp,
} }) => {
  const title = `サイト内検索`
  const debounceWait = 100

  const [searchText, setSearchTextReal] = useState(``)

  const setSearchText = useMemo(() =>
    debounce((event) => {
      setSearchTextReal(event.target.value || ``)
    }, debounceWait), [debounceWait, setSearchTextReal])
  const onSearch = useCallback((event) => {
    setSearchText(event)
  }, [setSearchText])

  const items = searchText ? filterWithSearchText({ allItems, searchText }) : []

  return (
    <Layout
      title={title}
      breadcrumbs={[{ label: title, href: null }]}
      CustomHeader={(
        <div className="pc:my-4 tablet_sp:my-3">
          <HeaderImage
            pcImage={header_image_pc}
            spImages={header_images_sp.nodes}
          />
        </div>
      )}
    >
      <PageTitle>
        <div className="flex flex-row items-center">
          <span>{title}</span>
          <FiSearch className="ml-3" />
        </div>
      </PageTitle>
      <div>
        {/* 検索ボックス */}
        <div className="mb-8">
          <input type="search"
            placeholder="検索ワード"
            className="w-full px-4 py-2 border rounded"
            onChange={onSearch}
          />
        </div>
        {/* 検索結果 */}
        <div>
          {searchText ? (
            (items.length > 0) ? (
              items.map((item) => (
                <Link key={item.path} to={item.path} className="flex flex-row items-start my-2 py-2 text-textBase no-underline">
                  <Thumbnail src={item?.thumbnail?.publicURL} alt={item.title} className="flex-none pc:float-left pc:mr-5 pc:mb-3 tablet_sp:float-left tablet_sp:mr-4 tablet_sp:mb-3" />
                  <div>
                    <h3 className="mt-1 mb-2 pc:text-lg tablet_sp:text-sm">{item.title}</h3>
                    <div className="mb-1 break-words tablet_sp:clear-both tablet_sp:text-xs tablet_sp:leading-normal">{item.description}</div>
                  </div>
                </Link>
              ))
            ) : `合致するページが見つかりませんでした。`
          ) : `検索ワードを入力してください。`}
        </div>
      </div>
    </Layout>
  )
}

/**
 * ヘルパー: 検索キーワードでアイテムを絞り込む
 */
const filterWithSearchText = ({ allItems, searchText }) => {
  const searchWords = extractWords(lower(searchText))

  return allItems.filter(({ title, description }) => (
    searchWords.some((p) => (
      lower(title).includes(p) || lower(description).includes(p)
    ))
  ))
}

filterWithSearchText.propTypes = {
  allItems: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  })),
  searchText: PropTypes.string.isRequired,
}

/**
 * 小文字化
 */
const lower = (orig) => orig.toLowerCase()

/**
 * 小文字化 → 分割 → 空文字列を除去
 */
const extractWords = (text) => {
  return (text || ``).split(/\s+/).filter(p => p)
}

/**
 * サムネール
 *
 * TODO: 画像の幅・高さがレイアウトや検索数によって変動するのを防ぐ
 */
const Thumbnail = ({ src, alt, className }) => {
  if (!isNil(src)) {
    return (
      <img src={src} alt={alt} className={className} width={120}
        style={{
          width: `120px`,
          aspectRatio: `1 / 1`,
          objectFit: `contain`,
        }}
      />
    )
  }

  // `src` が `null` の場合はフォールバック画像を表示する
  return (
    <StaticImage
      src="../images/tbsearchitem/thumbnail.png"
      alt={alt}
      width={120}
      aspectRatio={1}
      className={className}
    />
  )
}

Thumbnail.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
}

export default Page

export const query = graphql`
  query SearchQuery {
    allTbSearchItem(sort: {fields: [date_for_sort, path], order: [DESC, ASC]}) {
      nodes {
        title
        path
        thumbnail {
          publicURL
        }
        description
      }
    }

    # ヘッダー画像（ PC ）
    header_image_pc: file(
      relativeDirectory: {eq: "tbpage/search"},
      name: {eq: "P1"},
      internal: {mediaType: {glob: "image/*"}}
      sourceInstanceName: {eq: "images"}
    ) {
      src: publicURL
    }

    # ヘッダー画像（ SP / Tablet ）
    header_images_sp: allFile(
      filter: {
        relativeDirectory: {eq: "tbpage/search"}
        name: {glob: "S*"}
        internal: {mediaType: {glob: "image/*"}}
        sourceInstanceName: {eq: "images"}
      }
      sort: {order: ASC, fields: name}
    ) {
      nodes {
        src: publicURL
      }
    }
  }
`
